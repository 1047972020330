<template>
  <component :is="'b-card'">

    <!-- Alert: No item found -->
    <b-tabs
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{$t("Account")}}</span>
        </template>
        <distributor-edit-tab
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{$t("Information")}}</span>
        </template>
        <distributor-information-tab class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import distributorEditTab from './distributorEditTab.vue'
import distributorInformationTab from './distributorInformationTab.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    distributorEditTab,
    distributorInformationTab,
  },
  
}
</script>

<style lang="scss">
@media screen and (max-width: 550px) {
   .vue-image-crop-upload .vicp-wrap {
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
    position: fixed;
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 300px;
    height: 500px;
    padding: 25px;
    background-color: #fff;
    border-radius: 2px;
    -webkit-animation: vicp 0.12s ease-in;
    animation: vicp 0.12s ease-in; 
    }
    .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area{
      height: 350px !important;
    }
}
.vicp-operate a{
  color: #7367f0 !important;
  font-weight: bold;
}
</style>
